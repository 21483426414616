.TitleFourByFourCards h2 {
    font-family: var(--alternative-font);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2.25rem;
    text-align: center;
    margin-bottom: 50px;
}

.TitleFourByFourCards h2 em {
    font-style: normal;
    color: var(--color2-color);
}
