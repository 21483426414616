@import url("vars.css");

/* Private global variables */
:root {
    --default-animation-duration: 0.3s;
}

body {
    margin: 0;
    font-family: var(--main-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: var(--base-font-size);
    color: var(--default-color);
    background: var(--background);
}
