.BannerText em {
    font-style: normal;
    color: var(--color2-color);
}

.BannerText .first {
    font-family: var(--alternative-font);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2.25rem;
}

.BannerText .second {
    font-size: 1.5rem;
}
