.Banner .image-container,
.Banner .text-container {
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.Banner2 .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.Banner2 .image-container > * {
    width: 60%;
}
