.Member .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Member .image-container > * {
    width: 60%;
}

.Member .image-container {
    margin-bottom: 18px;
}

.Member .image-container img {
    border-radius: 100px;
}

.Member .image-container img.with-border {
    border-color: var(--color1-color);
    border-width: 4px;
    border-style: solid;
}

.Member .name {
    margin-bottom: 8px;
    font-family: var(--alternative-font);
    font-size: 2rem;
    text-align: center;
}

.Member .title {
    margin-bottom: 32px;
    text-align: center;
    text-transform: uppercase;
    color: var(--color1-color);
    font-weight: bold;
}

.Member em {
    color: var(--color2-color);
}

.Member .description {
    margin-bottom: 18px;
}

.Member a,
.Member a:hover {
    color: black;
}
