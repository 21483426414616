.Header {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.Header.scroll {
    background-color: white;
    box-shadow: 0 10px 10px rgb(225, 225, 225);
}

.Header .logo-menu-container {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .Header .logo-menu-container {
        flex-direction: column;
        align-items: center;
    }
}

@media print {
    .Header {
        display: none;
    }
}
