.OneByTwoTextImage.dark {
    color: white;
    background: linear-gradient(180deg, var(--color2-color) 0%, var(--color1-color) 100%);
}

.OneByTwoTextImage .text-container,
.OneByTwoTextImage .image-container {
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}
.OneByTwoTextImage  em {
    font-weight: bold;
    font-style: normal;
    color: var(--color2-color);
}
