.Footer {
    padding-top: 50px;
    padding-bottom: 50px;
}

.Footer a,
.Footer a:hover {
    color: var(--default-color);
}

.Footer .logo-container {
    text-align: left;
}

.Footer .logo-container,
.Footer .summary-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.Footer .summary {
    font-weight: 600;
    font-size: 1.5rem;
    font-family: var(--alternative-font);
    color: var(--color1-color);
}

.Footer .summary strong {
    font-weight: 700;
}

.Footer .legal p {
    margin-bottom: 1rem;
}

.Footer .legal p:last-child {
    margin-bottom: 0;
}

@media print {
    .Footer {
        display: none;
    }
}
