.Card {
    border-radius: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    padding: 24px 32px;
    background-color: white;
}

.Card .image {
    margin-bottom: 32px;
    text-align: center;
}

.Card .title {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--color1-color);
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 8px;
}

.Card .text {
    text-align: justify;
}
