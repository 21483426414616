h2.SectionTitle {
    font-family: var(--alternative-font);
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: var(--bs-body-line-height);
}

h2.SectionTitle em {
    font-style: normal;
    color: var(--color2-color);
}
