.CookieConsent {
    position: fixed;
    bottom: 0;
    background-color: black;
    color: white;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.CookieConsent .button-container {
    text-align: right;
}
