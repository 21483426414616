.App {
    overflow: hidden;
}

.App .content {
    --header-height: 84px;
    --content-base-padding: 50px;

    padding-top: calc(var(--header-height) + var(--content-base-padding));
}

.App .content > *:not(:first-child) {
    padding-top: var(--content-base-padding);
    padding-bottom: var(--content-base-padding);
}

@media screen and (max-width: 767px) {
    .App .content {
        --header-height: 252px;
    }
}

@media print {
    .App .content {
        padding-top: 0;
    }
}
