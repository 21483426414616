/* Used as main font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/* Used as alternative font */
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');

:root {
    --default-color: #141b2d;
    --background: repeat-y center/100% url("./components/background.png");
    --base-font-size: 16px;
    --color1-color: #1b307a;
    --color2-color: #e6007a;
    --main-font: "Montserrat", sans-serif;
    --alternative-font: "Lexend Deca", sans-serif;
}
